// src/redux/reducers/index.ts
import { combineReducers } from 'redux';
import counterReducer, { CounterState } from './counterReducer';  

import loginReducer, { LoginState } from './loginReducer';

import dataReducer, { DataState } from './dataReducer';

export interface RootState { 
  counter: CounterState;
  login: LoginState; 
  data: DataState;
}
const rootReducer = combineReducers({
  counter: counterReducer,
  login: loginReducer,
  data:dataReducer
});


export default rootReducer;
