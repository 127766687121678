import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBIcon,
  MDBBtn
} from 'mdb-react-ui-kit';

export default function Footer() {
  return (
    <MDBFooter className='bg-light text-center text-white'>
      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
        © 2024 Copyright:
        <a className='text-white' href='#/'>
          Nanoproject.com
        </a>
      </div>
    </MDBFooter>
  );
}