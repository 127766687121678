// actions/dataActions.ts
import { LOGIN_SUCCESS_REQUEST, LOGGOUT_SUCCESS_REQUEST } from './types';



export const login_success_request = (data: any) => ({
  type: LOGIN_SUCCESS_REQUEST,
  payload: data,
});

export const logout_success_request = (data: any) => ({
  type: LOGGOUT_SUCCESS_REQUEST, 
  payload: data,
});
