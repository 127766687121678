import React from 'react';
// import logo from './logo.svg';
import Counter from './components/Counter';
import './App.css';
import './styles/shortcode.css';
import Footer from './components/footer';

import Header from './components/header';
import Login from './pages/Login';

// import { BrowserRouter as Router, Route } from 'react-router-dom';
import AppRoutes from './components/AppRoutes';

function App() {
  return (
    <div className="App">     
    <Header/> 
 <div className='content mt-5'>
 <div className='container'>    
 <div className='row'>    
 <div className='col-lg-12'>    
 {/* <Register/> */}
      {/* <Counter /> */}
     {/* <Login/> */}
     {/*  <Users/>
      <Forgotpass/> */}

<AppRoutes/>
    

    
    </div>
    </div>
    </div>
    </div>
    <Footer/>
    </div>
  );
}

export default App;
