import React, { useEffect, useState } from 'react';
// import AppRoutes from './AppRoutes';
// import Loginstatus from './loginstatus-nouse';
import { useSelector, useDispatch } from 'react-redux'; 
import { RootState } from '../redux/reducers';
import { fetchDataRequest, fetchDataSuccess, fetchDataFailure } from '../redux/actions/dataActions';
import {login_success_request, logout_success_request} from '../redux/actions/loginActions';
import LogoutComponent from './logoutComponent';
import axios from 'axios';


export default function Navbar() {

  const dispatch = useDispatch()

  const loginstatus = useSelector((state:RootState) => state?.login);
  const dataApi  = useSelector((state:RootState) => state?.data);
  // const { data } = useSelector((state:RootState) => state);
// console.log(loginstatus?.loginstatus,"login status")
// console.log(dataApi?.data,"dataApi")
// console.log(data,"data")
// console.log(counter,"counter")


  const [open,setOpen]=useState(false);
  const[islogin, setIslogin]=useState(false);


const toggleMenu = () => {
  // console.log("togglemenu hitted"); 
  setOpen(!open)
  // console.log("menu hitted", open)
  document.querySelector('body')?.classList.toggle('activeMenu');
  document.querySelector('#sidebarMenu')?.classList.toggle('show');
}


useEffect(()=> {
  dispatch(fetchDataRequest());
  const userAuth = localStorage.getItem('loginToken');
  
  if(userAuth !== null){
    // console.log(userAuth, " userAuth")
    // console.log("login success")
    dispatch(login_success_request(true));
  } else {
    // console.log("no login")
    const currentURL = window.location.pathname;
    // console.log(currentURL, " currentURL")
    // window.location.href = '/login'
 

  }

  const fetchData = async () => {
    try { 
      const headers = {
        'Authorization': `Bearer ${userAuth}`,
        'Content-Type': 'application/json',   
      };
      const apiUrl = 'https://node.exactprint.co.uk/api/user/getUsers?search=&start=0&length=10';
      axios.get(apiUrl, { headers })
  .then((response:any) => {
    dispatch(fetchDataSuccess(response?.data?.data));
   
  })
  .catch((error:any) => {
    // Handle errors
    console.error('Error:', error);
  });  
    } catch (error) {
      dispatch(fetchDataFailure(error));
    }
  };
  fetchData();   
},[dispatch])

  return (
    <>
    
     {/* <Router> */}
    {/* <AppRoutes/> */}
    <>
  {/*Main Navigation*/}
  <header className='fixed-top bg-white'>

     {/* Navbar */}
     <nav
      id="main-navbar"
      className="navbar navbar-expand-lg navbar-light bg-white pos-rel"
    >
      {/* Container wrapper */}
      <div className="container-fluid">
        {/* Toggle button */}
        <button
          className="navbar-toggler"
          type="button"
          data-mdb-toggle="collapse"
          data-mdb-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleMenu}
        >
          <i className="fas fa-bars" />
        </button> 
        {/* Brand */}
          
      </div>
      {/* Container wrapper */}
    </nav>
    {/* Navbar */}

    {/* Sidebar */}
    <nav
      id="sidebarMenu"
      className="collapse d-lg-block sidebar collapse bg-white pos-rel"
    >
      <div className="position-rel">
        <div className="list-group list-group-flush mx-1 mt-2">
        <a className="navbar-brand list-group-item list-group-item-action py-2 ripple d-flex flex-start" href="/">
          <img
            src="./app-logo.png"
            height={25}
            alt=""
            loading="lazy"
          />
          <span className='ps-2'>Neno Network</span>
        </a>
         

         
    {loginstatus?.loginstatus ? (<>
      <a
            href="/dashboard"
            className="list-group-item list-group-item-action py-2 ripple d-flex flex-start active"
            aria-current="true"
          >
            <i className="fas fa-tachometer-alt fa-fw me-3" />
            <span>Main dashboard</span>
          </a>
          <a
            href="/users"
            className="list-group-item list-group-item-action py-2 ripple d-flex flex-start"
            aria-current="true"
          >
            <i className="fas fa-tachometer-alt fa-fw me-3" />
            <span>Users</span>
          </a> 

          <a href="/logout" className="list-group-item list-group-item-action py-2 ripple d-flex flex-start" aria-current="true">
            <i className="fas fa-tachometer-alt fa-fw me-3" />
            <span>Logout</span>
          </a> 

          {/* <button className="list-group-item list-group-item-action py-2 ripple d-flex flex-start" onClick={handleLogout}>  <i className="fas fa-tachometer-alt fa-fw me-3" /><span>Logout</span></button> */}

        {/* <LogoutComponent/> */}

          </>) : (<><a
            href="/register"
            className="list-group-item list-group-item-action py-2 ripple d-flex flex-start"
            aria-current="true"
          >
            <i className="fas fa-tachometer-alt fa-fw me-3" />
            <span>Register</span>
          </a>

          <a
            href="/login"
            className="list-group-item list-group-item-action py-2 ripple d-flex flex-start"
            aria-current="true"
          >
            <i className="fas fa-tachometer-alt fa-fw me-3" />
            <span>Login</span>
          </a></>)}
         

          


        </div>
      </div>
    </nav>
    {/* Sidebar */}
   
  </header>
  {/*Main Navigation*/}
</>

      {/* </Router>   */}
    </>
  );
}