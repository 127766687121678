import React, { useEffect, useState } from 'react';
import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { useSelector, useDispatch } from 'react-redux'; 
import { RootState } from '../redux/reducers';
import { Link } from 'react-router-dom';
import { fetchDataFailure, fetchDataRequest, fetchDataSuccess } from '../redux/actions/dataActions';
import { login_success_request } from '../redux/actions/loginActions';
import axios from 'axios';
// import { useState } from 'react/ts5.0';

export default function Users() {
  const[data,setData]=useState([]);
  const[loading,setLoading] = useState(true);

  const dispatch = useDispatch() 
  const dataApi  = useSelector((state:RootState) => state?.data);
  // console.log(dataApi, " dataApi.data") 

  useEffect(()=> {
    dispatch(fetchDataRequest());
    const userAuth = localStorage.getItem('loginToken');
    if(userAuth !== null){
      dispatch(login_success_request(true));
      if(dataApi?.data?.length > 0) {
        // console.log("data here")
        // setData(dataApi?.data);
        // setLoading(false)
      }      
    } else {
      // console.log("no login")
      const currentURL = window.location.pathname;
      // console.log(currentURL, " currentURL")
      // window.location.href = '/login'
   
  
    } 

  },[])



// console.log(dataApi?.data, " data-setLoading");
 

  return (
    <MDBTable align='middle'>
      <MDBTableHead>
        <tr>
          <th scope='col'>Name</th>
          <th scope='col'>Email</th>
          <th scope='col'>Status</th>          
          <th scope='col'>Date Added</th>          
          <th scope='col'>Connected Device</th>          
          <th scope='col'>Delete</th>          
          <th scope='col'>Sync Logs</th>          
          <th scope='col'>Actions</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
    {dataApi?.data !== null ? (dataApi?.data.map( (item:any,index:number) => {
      return (<tr key={index}>
        <td>
          <div className='d-flex align-items-center'>
            <img
              src='https://mdbootstrap.com/img/new/avatars/8.jpg'
              alt=''
              style={{ width: '45px', height: '45px' }}
              className='rounded-circle'
            />
            <div className='ms-3'>
              <p className='fw-bold mb-1'>{item?.name}</p>
            
            </div>
          </div>
        </td>
        <td>
          <p className='fw-normal mb-1'>{item?.email}</p>
        </td>
        <td>
          <MDBBadge color='success' pill>
            Active
          </MDBBadge>
        </td>
        <td>13May2002</td>

        <td>
          <MDBBtn color='link' rounded size='sm' className='btn-secondary'>
            <Link to={`/profile/${item?.id}`}>connected device</Link>
          </MDBBtn>
        </td>

        <td>
          <MDBBtn color='link' rounded size='sm' className='btn-secondary'>
            <Link to={`/profile/${item?.id}`}>delete</Link>
          </MDBBtn>
        </td>

        <td>
          <MDBBtn color='link' rounded size='sm' className='btn-secondary'>
            <Link to={`/profile/${item?.id}`}>sync log</Link>
          </MDBBtn>
        </td>
        <td>
          <MDBBtn color='link' rounded size='sm' className='btn-secondary'>
            <Link to={`/profile/${item?.id}`}>Update</Link>
          </MDBBtn>
        </td>
      </tr>)
    })): ( <tr>
      <td colSpan={8}>Loading...User</td> 
    </tr>)}
        
      </MDBTableBody>
    </MDBTable>
  );
}