import { LOGIN_SUCCESS_REQUEST, LOGGOUT_SUCCESS_REQUEST } from '../actions/types';

export interface LoginState {
    loginstatus: boolean;
  }
  
  const initialState: LoginState = {
    loginstatus: false, 
  };
  
  // type CounterAction = { type: 'LOGIN_SUCCESS_REQUEST' } | { type: 'LOGGOUT_SUCCESS_REQUEST' };  
  // const LoginReducer = (state = initialState, action: CounterAction): LoginState => {



  const LoginReducer = (state = initialState, action: any): LoginState => {
    switch (action.type) {
      case LOGIN_SUCCESS_REQUEST:
        return { ...state, loginstatus: action.payload };
      case LOGGOUT_SUCCESS_REQUEST:
        return { ...state, loginstatus: action.payload };
      default:
        return state;
    }
  };
  
  export default LoginReducer;
  