import React,{useEffect, useState} from 'react';
import axios from 'axios';
import {  MDBBtn, MDBContainer,  MDBRow,  MDBCol,  MDBInput}
from 'mdb-react-ui-kit';
import { Link, useNavigate } from 'react-router-dom';
// import Loginstatus from '../components/loginstatus-nouse';
import { useDispatch } from 'react-redux';
import { login_success_request, logout_success_request } from '../redux/actions/loginActions';
import { API_BASE_URL } from '../config';

interface LoginResponse {
  success: boolean;
}

const Login:React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loginStatus, setLoginStatus] = useState<string>('');

  const [isLoggedIn, setLoggedIn] = useState(false);

  const dispatch = useDispatch()

useEffect(()=> {
  localStorage.removeItem('loginToken');  
  dispatch(logout_success_request(false));
},[dispatch])


type LoginResponse = {
  token: string;
  user: any;  
};



   const handleLogin = async (e: React.FormEvent) => {   
    e.preventDefault();
    try {
      const response = await axios.post<LoginResponse>(`${API_BASE_URL}user/signin`, { email, password });  
    
      if (response?.data?.token !== null) {
        console.log("responseTT",response?.data?.user)
        dispatch(login_success_request(true));
        console.log(response?.data)
        setLoginStatus('Login successful');
        dispatch(logout_success_request(true))      
        localStorage.setItem('loginToken', response?.data?.token);    
        localStorage.setItem('userdata', JSON.stringify(response?.data?.user));
        setLoggedIn(true);
        setTimeout(() => {            
          navigate('/dashboard');
          return null;
        
        }, 300)

      } else {
        console.log("Login failed")
        setLoginStatus('Login failed. Please check your credentials.');
      }
    } catch (error) {
      console.error('An error occurred while logging in:', error);
      setLoginStatus('An error occurred while logging in.');
    }
  };

  return (
    <MDBContainer className="my-5 gradient-form">
      <MDBRow>

        <MDBCol col='6' className="mb-5">
          <div className="d-flex flex-column ms-5">

            <div className="text-center">
              <img src="./app-logo.png"
                style={{width: '185px'}} alt="logo" />
              <h4 className="mt-1 mb-5 pb-1">Neno Network</h4>
            </div>

            <p>Please login to your account</p>

<form onSubmit={handleLogin} className='testclass'>
            <MDBInput className='mb-4' label='Email address' id='email' type='email' value={email} onChange={(e) => setEmail(e.target.value)}/>
            <MDBInput className='mb-4' label='Password' id='password' type='password' value={password} onChange={(e) => setPassword(e.target.value)}/>


            <div className="text-center pt-1 mb-5 pb-1">
              <MDBBtn className="mb-4 w-100 gradient-custom-2" type="submit">Sign in</MDBBtn>
              <a className="text-muted" href="#!">Forgot password?</a>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-center pb-4 mb-4">
              <p className="mb-0">Don't have an account?</p>
              <MDBBtn outline className='mx-2' color='danger'>
              <Link to="/register">register test</Link>   
              </MDBBtn>
            </div>
            </form>
          </div>

        </MDBCol>

        <MDBCol col='6' className="mb-5">
          <div className="d-flex flex-column  justify-content-center gradient-custom-2 h-100 mb-4">

            <div className="text-white px-3 py-4 p-md-5 mx-md-4">
              <h4 className="mb-4">We are more than just a company</h4>
              <p className="small mb-0">The only place where success comes before work is in the dictionary." - Vidal Sassoon
              </p>
              <p className="small mb-0">Success is not final, failure is not fatal: It is the courage to continue that counts." - Winston Churchill
              </p>
            </div>

          </div>

        </MDBCol>

        

      </MDBRow>

   


    </MDBContainer>
  );
}

export default Login;