// reducers/dataReducer.ts
import { FETCH_DATA_REQUEST, FETCH_DATA_SUCCESS, FETCH_DATA_FAILURE } from '../actions/types';

export interface DataState {
  loading: boolean;
  data: any;
  error: any;
}

const initialState: DataState = {
  loading: true,
  data: null,
  error: null,
};

const dataReducer = (state = initialState, action: any): DataState => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_DATA_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case FETCH_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default dataReducer;
