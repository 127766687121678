import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Users from '../pages/users';
import Login from '../pages/Login';
import Register from '../pages/register';
import Profile from '../pages/profile';
import Dashboard from '../pages/dashboard';
import LogoutComponent from './logoutComponent';


const AppRoutes = () => {
  return (
    <>
    <Router> 
<Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/users" element={<Users />} />        
        <Route exact path="/logout" element={<LogoutComponent />} />
        <Route exact path="/login" element={<Login />} />        
        <Route exact path="/register" element={<Register />} />      
        <Route exact path="/profile/:userId" element={<Profile/>} />    
      
      </Routes>
      </Router> 
    </>
  );
};

export default AppRoutes;
