import React from 'react';
import AdminCardSection1 from '../components/AdminCardSection1';
import {  MDBBtn, MDBContainer,  MDBRow,  MDBCol,  MDBInput, MDBCard, MDBCardBody, MDBCardImage} from 'mdb-react-ui-kit';
const Dashboard:React.FC = () => {
  const userDataString = localStorage.getItem('userdata');
  const userData = userDataString ? JSON.parse(userDataString) : null;
  console.log(userData, " userData")
    return (
        <React.Fragment>   
             <MDBContainer className="py-5">   
        <MDBRow>



          <MDBCol lg="4">
            <MDBCard className="mb-4">
                        <div>
                          
                         <MDBCardBody className="text-center">
                <MDBCardImage
                  src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                  alt="avatar"
                  className="rounded-circle"
                  style={{ width: '150px' }}
                  fluid />
                <p className="text-muted mb-1">{userData?.username}</p>
                <p className="text-muted mb-4">{userData?.email}</p> 
         
              </MDBCardBody>
                           </div>
                  
            </MDBCard>           
          </MDBCol>

          <MDBCol lg="8">
          <AdminCardSection1 />
            </MDBCol>

          </MDBRow>
          </MDBContainer>  
       
      </React.Fragment>
    );
};

export default Dashboard;