// LogoutComponent.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutComponent:React.FC = () => {
  const navigate = useNavigate();

  useEffect(()=>{
    navigate('/login');
  })


  return (
    <div>
      <h2>Logout Component</h2>
      
    </div>
  );
};

export default LogoutComponent;
