import React, { useEffect, useState } from 'react';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBProgress,
  MDBProgressBar,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem
} from 'mdb-react-ui-kit'; 
import { useSelector } from 'react-redux';
import { RootState } from '../redux/reducers';
import { useParams } from 'react-router-dom';

const Profile:React.FC = () => {
    const { userId } = useParams();  
    const [userDetails, setUserDetails] = useState({
      username: '',
      id: '',
      email: '',
      created_on: '',
      updated_on: ''
    });
    console.log(userId, " user")
    const dataApi  = useSelector((state:RootState) => state?.data);

    console.log(dataApi, " dataApi dataApi")

    useEffect(()=> {
      if(dataApi?.data !== null){
   dataApi?.data.map((user:any)=>{
    if(userId == user.id) {
      console.log(user.username, " SSS")
      setUserDetails(prevUserDetails => ({ ...prevUserDetails, 
        username:user?.username,
        id:user?.id,
        email:user?.email,
        created_on:user?.created_on,
        updated_on:user?.updated_on
      }))
    }
      })
    }
    },[dataApi])


    if (dataApi?.loading) {
      return <p>Loading...</p>;
    }
    if (dataApi?.error) {
      return <p>Error: {dataApi?.error?.message}</p>;
    }



    console.log(userDetails, ' userDetaiuserDetails.usernamels')
  return (
    <section style={{ backgroundColor: '#eee' }}>
      <MDBContainer className="py-5">   
        <MDBRow>
          <MDBCol lg="4">
            <MDBCard className="mb-4">                                           
                            <MDBCardBody className="text-center">                              
                <MDBCardImage
                  src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                  alt="avatar"
                  className="rounded-circle"
                  style={{ width: '150px' }}
                  fluid />
                <p className="text-muted mb-1">{userDetails?.username}</p>               
                <div className="d-flex justify-content-center mb-2">               
                  <MDBBtn outline className="ms-1">Password Update</MDBBtn>
                </div>
              </MDBCardBody>
                         
                   
            </MDBCard>           
          </MDBCol>

          
          <MDBCol lg="8">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>UserName</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted text-left">{userDetails?.username}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Email</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted text-left">{userDetails?.email}</MDBCardText>
                  </MDBCol>
                </MDBRow>

                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Password</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
               
                    <input type="password" id="form2Example2" className="form-control" />
                  </MDBCol>
                </MDBRow>

                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>New Password</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
               
                    <input type="password" id="form2Example2" className="form-control" />
                  </MDBCol>
                </MDBRow>

                <hr />
                <MDBRow>
                  <MDBCol sm="12">      
                  <button type="button" className="btn btn-primary  mb-4">Update Password</button>
                  </MDBCol>               
                </MDBRow>

              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}
export default Profile;